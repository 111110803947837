import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import styles from "./SpinnerLoader.module.css";

export const SpinnerLoader = ({ size = "small", children }) => {
  return (
    <Spin
      size={size}
      indicator={<LoadingOutlined spin />}
      className={styles.SpinnerLoader}
    >
      {children}
    </Spin>
  );
};

SpinnerLoader.propTypes = {
  size: PropTypes.oneOf(["small", "default", "large"]),
  children: PropTypes.node,
};
