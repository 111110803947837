import React from "react";
import { Modal as AntdModal } from "antd";

import styles from "./bdoModal.module.css";
import { BDOButton, ButtonColours } from "../Button";

export const Modal = ({
  isModalOpen,
  children,
  handleSubmit,
  handleCancel,
  title,
  isDisabled = false,
  subTitle,
  submitButtomText = "Download",
  width = 420,
  viewActions = true,
  isLoading,
}) => {
  return (
    <AntdModal
      width={width}
      title={title}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={
        viewActions ? (
          <div className={styles.footerWrapper}>
            <BDOButton
              onClick={handleCancel}
              variant={ButtonColours.DEFAULT}
              hasMinWidth
            >
              Dismiss
            </BDOButton>
            <BDOButton
              hasMinWidth
              disabled={isDisabled}
              onClick={handleSubmit}
              variant={ButtonColours.RED}
              loading={isLoading}
            >
              {submitButtomText}
            </BDOButton>
          </div>
        ) : null
      }
    >
      <div className={styles.modalContentWrapperStyles}>
        <p className={styles.subTitleStyles}>{subTitle}</p>
        {children}
      </div>
    </AntdModal>
  );
};
