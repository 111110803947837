import { useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { scopesRequest } from "../config/authConfig";

const baseUrl = `https://${process.env.REACT_APP_API_BASE_URL}/api`;

/**
 * Custom hook to fetch data from an API endpoint.
 * @param {Object} options - The options object.
 * @param {string} options.endpoint - The API endpoint to fetch data from.
 * @param {boolean} options.shouldSkip - Whether to skip fetching data or not.
 * @param {function} options.onSuccess - The function to call after successfully fetching data.
 * @param {function} options.onError - The function to call after unsuccessfully fetching data.
 * @returns {Object} - An object containing the fetched data, loading state, and a refetch function.
 */
export const useGet = ({
  endpoint,
  shouldSkip,
  onSuccess,
  onError,
  autoExecute = true,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { instance, accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;

  const getData = useCallback(
    async (params = {}) => {
      const hasParams = Object.keys(params).length;

      const authRequest = {
        scopesRequest,
        account: accounts[0],
      };
      const queryParams = new URLSearchParams();
      if (hasParams) {
        for (let key in params) {
          queryParams.append(key, params[key]);
        }
      }
      setLoading(true);

      try {
        const tokenResponse = await instance.acquireTokenSilent(authRequest);
        const url = `${baseUrl}/${endpoint}`;
        let urlWithParams = "";
        if (hasParams) {
          if (url.includes("?")) {
            urlWithParams = `${url}&${queryParams.toString()}`;
          } else {
            urlWithParams = `${url}?${queryParams.toString()}`;
          }
        } else {
          urlWithParams = url;
        }
        const response = await fetch(urlWithParams, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenResponse.accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setData(data);
          onSuccess && onSuccess(data, response);
        } else {
          setError(response);
          onError && onError(response);
        }
      } catch (error) {
        console.log(error);
        setError(error);
        onError && onError();
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [endpoint, instance, accounts, onError]
  );

  useEffect(() => {
    if (
      isAuthenticated &&
      !shouldSkip &&
      !data &&
      !error &&
      !loading &&
      autoExecute
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, getData, isAuthenticated, shouldSkip]);

  function refetch() {
    setData(null);
    setError(null);
  }

  return { data, loading, refetch, mutate: getData };
};
