import React, { useRef } from "react";
import { ConfigProvider } from "antd";
import { Route, Routes, Navigate } from "react-router-dom";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { scopesRequest } from "./config/authConfig";
import bdoAntTheme from "./ant-bdo-theme.json";
import AppLogout from "./services/api/utilities/AppLogout";
import { MainLayout } from "./layout";
import { ConfigContextProvider, DrawerContextProvider } from "./contexts";
import { AdminPage } from "./Pages";
import { LandPage } from "./Pages/landPage/LandPage";

import "./Pages/landPage/bdo-personas-gpt.css";

function App() {
  const landPageRef = useRef();
  const clearChat = () => {
    if (landPageRef.current) {
      landPageRef.current.clearChat(true);
    }
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={scopesRequest}
    >
      <ConfigProvider theme={bdoAntTheme}>
        <DrawerContextProvider>
          <ConfigContextProvider>
            <MainLayout clearChat={clearChat}>
              <Routes>
                <Route path="/" element={<LandPage ref={landPageRef} />} />
                <Route
                  path="/admin"
                  element={<AdminPage ref={landPageRef} />}
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </MainLayout>
          </ConfigContextProvider>
        </DrawerContextProvider>
        <AppLogout />
      </ConfigProvider>
    </MsalAuthenticationTemplate>
  );
}

export default App;
