import React from "react";
import { Badge } from "antd";
import { NotificationOutlined } from "@ant-design/icons";

import { useDrawerContext } from "../../hooks/useDrawerContext";
import { FeedbackIcon } from "../../Icons/FeedbackIcon";
import { BDOButton, ButtonColours } from "../Button";

import styles from "./AccountItems.module.css";
import { useConfigContext } from "../../hooks/useConfigContext";
import { useMutate } from "../../hooks/useMutate";
import { routes } from "../../config/apiRoutes";

export const AccountItems = () => {
  const { setShowFeedbackDrawer, setShowUpdatesDrawer } = useDrawerContext();
  const { hasNewVersion, setHasNewVersion } = useConfigContext();

  const { mutate: markNotificationAsRead } = useMutate({
    endpoint: routes.markNotificationasRead,
    method: "PATCH",
  });

  const handleShareFeedback = () => {
    setShowFeedbackDrawer(true);
  };

  const handleViewUpdates = () => {
    setShowUpdatesDrawer(true);
    if (hasNewVersion) {
      setHasNewVersion(false);
      markNotificationAsRead({});
    }
  };
  return (
    <>
      <BDOButton
        Icon={
          <FeedbackIcon
            className={styles.feedbackIconStyles}
            onClick={handleShareFeedback}
          />
        }
        variant={ButtonColours.ICON}
        onClick={handleShareFeedback}
      ></BDOButton>
      <BDOButton
        Icon={
          <Badge
            dot={hasNewVersion}
            color="#E81A3B"
            className={styles.badgeStyles}
            placement="end"
          >
            <NotificationOutlined />
          </Badge>
        }
        variant={ButtonColours.ICON}
        onClick={handleViewUpdates}
      ></BDOButton>
    </>
  );
};
