import React, { useRef, useEffect, useState } from "react";
import { Input, Upload, Badge, Alert, notification, Image } from "antd";
import {
  PaperClipOutlined,
  SendOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { UploadFilePopover } from "../Popeovers";
import { UploadedFilesViewTags } from "../UploadedFilesViewTags";
import { useDrawerContext } from "../../hooks/useDrawerContext";
import { useSpeechHandler } from "../../hooks/useSpeechHandler"; // Import the custom hook
import { VoiceInput } from "../VoiceInput";
import { BDOButton, ButtonColours } from "../Button";
import { Modal } from "../Modal";
import BDOInput from "../BDOInput/BDOInput";

import styles from "./ChatInputTextArea.module.css";
import { routes } from "../../config/apiRoutes";
import { getImageByFileType, removeSourcesFromChatLog } from "../../utils";
import { useMutate } from "../../hooks/useMutate";
import { useConfigContext } from "../../hooks/useConfigContext";
import { SpinnerLoader } from "../SpinnerLoader";
import { imageFileRegex, MAX_CHARACTERS } from "../../config/constants";
import FileLoaderPreview from "../FileLoaderPreview/FileLoaderPreview";

const { TextArea } = Input;

let notificationKey = `open${Date.now()}`;
export const ChatInputTextArea = ({
  input,
  setInput,
  executeGPTRequest,
  isLoading,
  isSendDisabled,
  callGPTAPI,
  uploadProps,
  showFileUploadButton,
  isFileUploadLoading,
  uploadFileList,
  handleFileRemoveChange,
  errorMessage,
  setErrorMessage,
  chatLog,
  handleNotification,
  uploadedFile,
  textAreaRef,
}) => {
  const { isTypewriting } = useDrawerContext();
  const { config } = useConfigContext();
  const [api, contextHolder] = notification.useNotification();

  const exportChatConversation = !!config?.ExportChatConversation;

  const { listening, onStartListening, onStopListening } = useSpeechHandler(
    input,
    setInput
  );

  const openNotification = () => {
    handleCancelConversationModal();
    notificationKey = `open${Date.now()}`;

    api.open({
      placement: "bottomRight",
      message: "Exporting conversation.",
      type: "success",
      icon: (
        <LoadingOutlined className={styles.notificationLoadingIconStyles} />
      ),
      key: notificationKey,
      closeIcon: null,
      duration: 0,
    });
  };

  const [openDownloadConversationModal, setOpenDownloadConversationModal] =
    useState(false);

  const [isLoadingDownloadConversation, setIsLoadingDownloadConversation] =
    useState(false);

  const [conversationFileTitle, setConversationFileTitle] = useState("");

  const enableVoiceInput = process.env.REACT_APP_ENABLE_VOICE_INPUT === "true";
  const listeningMessage =
    process.env.REACT_APP_LISTENING_MESSAGE || "Listening...";

  const handleStartListening = () => {
    onStartListening();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleCloseErrorMessage?.();
    }, 5000);
    return () => clearTimeout(timer);
  }, [errorMessage]);

  const { mutate: exportPDF } = useMutate({
    endpoint: routes.exportConversation,
    method: "POST",
    onSuccess: async (data) => {
      try {
        const pdfBlob = await data.blob();
        const url = window.URL.createObjectURL(pdfBlob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `${conversationFileTitle}.pdf`;
        document.body.appendChild(a);
        a.click();

        a.remove();
        window.URL.revokeObjectURL(url);
        handleCancelConversationModal();
        handleNotification("Conversation downloaded successfully.", true);
        api.destroy(notificationKey);
      } catch (error) {
        setIsLoadingDownloadConversation(false);
        handleNotification("Something went wrong, Please try again", false);
        api.destroy(notificationKey);
      }
    },
    onError: () => {
      setIsLoadingDownloadConversation(false);
      handleNotification("Something went wrong, Please try again", false);
      api.destroy(notificationKey);
    },
  });

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);
  useEffect(() => {
    setConversationFileTitle("");
  }, [openDownloadConversationModal]);
  const handleCloseErrorMessage = () => {
    setErrorMessage?.({ isError: false, message: "" });
  };

  const handleSendClick = () => {
    if (!isSendDisabled && !listening) {
      callGPTAPI();
      setInput("");
    }
  };

  const handleDownloadConversationClick = () => {
    setOpenDownloadConversationModal(true);
  };

  const handleCancelConversationModal = () => {
    setConversationFileTitle("");
    setIsLoadingDownloadConversation(false);
    setOpenDownloadConversationModal(false);
  };

  const handleDownloadConversation = () => {
    setIsLoadingDownloadConversation(true);
    const uploadedFileNames = uploadFileList.map((file) => file.name);
    const exportConversationData = {
      uploadedFileNames,
      chatLog: removeSourcesFromChatLog(chatLog),
      exportFileName: conversationFileTitle,
    };
    openNotification();
    exportPDF({ body: JSON.stringify(exportConversationData) });
  };

  return (
    <>
      {contextHolder}
      <Modal
        isModalOpen={openDownloadConversationModal}
        setIsModalOpen={setOpenDownloadConversationModal}
        handleCancel={handleCancelConversationModal}
        title="Export conversation"
        subTitle="Export conversation for evidencing."
        isDisabled={!conversationFileTitle?.trim().length}
        handleSubmit={handleDownloadConversation}
        isLoading={isLoadingDownloadConversation}
      >
        <BDOInput
          label={"Title"}
          value={conversationFileTitle}
          onChange={(e) => setConversationFileTitle(e.target.value)}
          showCount
          maxLength={MAX_CHARACTERS}
        />
      </Modal>
      {errorMessage?.isError ? (
        <Alert
          onClose={handleCloseErrorMessage}
          className={styles.errorAlertStyles}
          message={errorMessage?.message}
          type="error"
          showIcon
          icon={<InfoCircleOutlined className={styles.errorAlertIcon} />}
          closable
        />
      ) : null}
      {listening && (
        <div className={styles.listeningMessageContainer}>
          <div className={styles.listeningMessage}>
            <Badge
              className={styles.badgeDot}
              status="processing"
              text={listeningMessage}
            />
          </div>
        </div>
      )}
      {isFileUploadLoading ? (
        <FileLoaderPreview uploadedFile={uploadedFile} />
      ) : (
        <>
          {uploadFileList?.length ? (
            <div className={styles.tagItemsWrapper}>
              <UploadedFilesViewTags
                files={uploadFileList}
                handleFileRemoveChange={handleFileRemoveChange}
              />
            </div>
          ) : null}
        </>
      )}
      <div className={styles.TextAreaIconsWrapper}>
        <div className={styles.flexWrapperContainer}>
          <div className={styles.iconWrapper}>
            {!listening && (
              <UploadFilePopover
                uploadProps={uploadProps}
                showFileUploadButton={showFileUploadButton || !isTypewriting}
              >
                <Upload
                  {...uploadProps}
                  disabled={!showFileUploadButton}
                  className={styles.paperClipStyles}
                >
                  <PaperClipOutlined
                    className={styles.paperClipIcon}
                    style={{
                      cursor:
                        !showFileUploadButton || isTypewriting
                          ? "not-allowed"
                          : "pointer",
                      color:
                        !showFileUploadButton || isTypewriting
                          ? "var(--bdo-charcoal-light)"
                          : "var(--sub-text)",
                    }}
                  />
                </Upload>
              </UploadFilePopover>
            )}

            {enableVoiceInput && (
              <VoiceInput
                listening={listening}
                handleStartListening={handleStartListening}
                onStopListening={onStopListening}
                isLoading={isLoading}
              />
            )}
          </div>
          <TextArea
            ref={textAreaRef}
            value={input}
            readOnly={listening}
            className={styles.textAreaInputStyles}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            id="transcript"
            autoSize={{ minRows: 1, maxRows: 6 }}
            placeholder="Enter your prompt here.."
            onKeyDown={(e) => {
              if (!isSendDisabled && !isLoading && !listening) {
                executeGPTRequest(e);
              }
            }}
          />
          {isLoading ? (
            <div className={styles.loadingIconStyles}>
              <SpinnerLoader />
            </div>
          ) : (
            <SendOutlined
              disabled={isSendDisabled || listening}
              onClick={handleSendClick}
              className={styles.sendIconStyles}
              style={{
                color:
                  isSendDisabled || listening
                    ? "var(--bdo-charcoal-light)"
                    : "var(--bdo-primary)",
                cursor: isSendDisabled || listening ? "not-allowed" : "pointer",
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.infoSection}>
        <p className={styles.infoText}>
          Personas uses AI. You are responsible for validating the output.
        </p>
        {chatLog?.length && exportChatConversation ? (
          <BDOButton
            className={styles.downloadButton}
            variant={ButtonColours.LINK}
            onClick={handleDownloadConversationClick}
            disabled={isLoading || listening}
          >
            <div className={styles.downloadConversationWrapper}>
              {" "}
              <DownloadOutlined /> Export conversation{" "}
            </div>
          </BDOButton>
        ) : null}
      </div>
    </>
  );
};
