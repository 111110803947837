import classNames from "classnames";

export const SortIcon = ({ className, sortOrder }) => {
  const getIconBySortType = () => {
    switch (sortOrder) {
      case "descend":
        return (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.40486 13.8308H9.7408C9.85857 13.8308 9.92361 13.9679 9.85154 14.0593L7.88279 16.5536C7.86964 16.5705 7.85283 16.584 7.83364 16.5934C7.81446 16.6027 7.79339 16.6076 7.77205 16.6076C7.75071 16.6076 7.72964 16.6027 7.71046 16.5934C7.69127 16.584 7.67446 16.5705 7.66131 16.5536L5.69256 14.0593C5.61873 13.9679 5.68553 13.8308 5.8033 13.8308H7.13924V4.51439C7.13924 4.43705 7.20252 4.37376 7.27986 4.37376H8.26424C8.34158 4.37376 8.40486 4.43705 8.40486 4.51439V13.8308Z"
              fill="#333"
            />
            <path
              d="M12.3885 7.15946L11.0525 7.15946C10.9347 7.15946 10.8697 7.02235 10.9418 6.93094L12.9105 4.43661C12.9237 4.4198 12.9405 4.4062 12.9597 4.39686C12.9789 4.38751 12.9999 4.38265 13.0213 4.38265C13.0426 4.38265 13.0637 4.38751 13.0829 4.39686C13.102 4.4062 13.1189 4.4198 13.132 4.43661L15.1008 6.93094C15.1746 7.02235 15.1078 7.15946 14.99 7.15946L13.6541 7.15946V16.4759C13.6541 16.5532 13.5908 16.6165 13.5135 16.6165H12.5291C12.4517 16.6165 12.3885 16.5532 12.3885 16.4759L12.3885 7.15946Z"
              fill="#858585"
            />
          </svg>
        );
      case "ascend":
        return (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.40486 13.8308H9.7408C9.85857 13.8308 9.92361 13.9679 9.85154 14.0593L7.88279 16.5536C7.86964 16.5705 7.85283 16.584 7.83364 16.5934C7.81446 16.6027 7.79339 16.6076 7.77205 16.6076C7.75071 16.6076 7.72964 16.6027 7.71046 16.5934C7.69127 16.584 7.67446 16.5705 7.66131 16.5536L5.69256 14.0593C5.61873 13.9679 5.68553 13.8308 5.8033 13.8308H7.13924V4.51439C7.13924 4.43705 7.20252 4.37376 7.27986 4.37376H8.26424C8.34158 4.37376 8.40486 4.43705 8.40486 4.51439V13.8308Z"
              fill="#858585"
            />
            <path
              d="M12.3885 7.15946L11.0525 7.15946C10.9347 7.15946 10.8697 7.02235 10.9418 6.93094L12.9105 4.43661C12.9237 4.4198 12.9405 4.4062 12.9597 4.39686C12.9789 4.38751 12.9999 4.38265 13.0213 4.38265C13.0426 4.38265 13.0637 4.38751 13.0829 4.39686C13.102 4.4062 13.1189 4.4198 13.132 4.43661L15.1008 6.93094C15.1746 7.02235 15.1078 7.15946 14.99 7.15946L13.6541 7.15946V16.4759C13.6541 16.5532 13.5908 16.6165 13.5135 16.6165H12.5291C12.4517 16.6165 12.3885 16.5532 12.3885 16.4759L12.3885 7.15946Z"
              fill="#333"
            />
          </svg>
        );

      default:
        return (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.40486 13.8308H9.7408C9.85857 13.8308 9.92361 13.9679 9.85154 14.0593L7.88279 16.5536C7.86964 16.5705 7.85283 16.584 7.83364 16.5934C7.81446 16.6027 7.79339 16.6076 7.77205 16.6076C7.75071 16.6076 7.72964 16.6027 7.71046 16.5934C7.69127 16.584 7.67446 16.5705 7.66131 16.5536L5.69256 14.0593C5.61873 13.9679 5.68553 13.8308 5.8033 13.8308H7.13924V4.51439C7.13924 4.43705 7.20252 4.37376 7.27986 4.37376H8.26424C8.34158 4.37376 8.40486 4.43705 8.40486 4.51439V13.8308Z"
              fill="#858585"
            />
            <path
              d="M12.3885 7.15946L11.0525 7.15946C10.9347 7.15946 10.8697 7.02235 10.9418 6.93094L12.9105 4.43661C12.9237 4.4198 12.9405 4.4062 12.9597 4.39686C12.9789 4.38751 12.9999 4.38265 13.0213 4.38265C13.0426 4.38265 13.0637 4.38751 13.0829 4.39686C13.102 4.4062 13.1189 4.4198 13.132 4.43661L15.1008 6.93094C15.1746 7.02235 15.1078 7.15946 14.99 7.15946L13.6541 7.15946V16.4759C13.6541 16.5532 13.5908 16.6165 13.5135 16.6165H12.5291C12.4517 16.6165 12.3885 16.5532 12.3885 16.4759L12.3885 7.15946Z"
              fill="#858585"
            />
          </svg>
        );
    }
  };
  return (
    <span className={classNames("anticon", className)}>
      {getIconBySortType()}
    </span>
  );
};
