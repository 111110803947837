import React, { useState, useEffect } from "react";
import styles from "./DragAndDropFiles.module.css";
import PropTypes from "prop-types";
import { Upload } from "antd";
import { allowedFileTypes } from "../../config/constants";
import classNames from "classnames";
import { useConfigContext } from "../../hooks/useConfigContext";
import { toWords } from "number-to-words";

const { Dragger } = Upload;

export const DragAndDropFiles = ({
  uploadFileList,
  beforeUpload,
  handleFileDropped,
  handleFileRemoveChange,
  setErrorMessage,
  isFileUploading,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { config } = useConfigContext();

  const numberToWords = toWords(config?.MaxFileUploadLimit ?? 1);

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
    setIsDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    setIsDragging(false);
  };

  const UploadFileProps = {
    name: "file",
    action: null,
    multiple: false,
    showUploadList: false,
    onChange: (event) => {
      if (uploadFileList?.length >= config?.MaxFileUploadLimit) {
        setErrorMessage({
          isError: true,
          message: `Upload failed. You can only upload ${numberToWords} file at a time. Please remove the existing document and try again.`,
        });
        return;
      }
      handleFileDropped(event?.file);
    },
    beforeUpload: (file) => {
      beforeUpload(file, true);
      return false;
    },
    onRemove: (event) => {
      handleFileRemoveChange(event?.file);
    },
  };

  useEffect(() => {
    let dragCounter = 0;

    const handleWindowDragEnter = (event) => {
      event.preventDefault();
      dragCounter++;
      setIsDragging(true);
    };

    const handleWindowDragOver = (event) => {
      event.preventDefault();
    };

    const handleWindowDragLeave = (event) => {
      event.preventDefault();
      dragCounter--;
      if (dragCounter === 0) {
        setIsDragging(false);
      }
    };

    const handleWindowDrop = (event) => {
      event.preventDefault();
      setIsDragging(false);
    };

    window.addEventListener("dragenter", handleWindowDragEnter);
    window.addEventListener("dragover", handleWindowDragOver);
    window.addEventListener("dragleave", handleWindowDragLeave);
    window.addEventListener("drop", handleWindowDrop);

    return () => {
      window.removeEventListener("dragenter", handleWindowDragEnter);
      window.removeEventListener("dragover", handleWindowDragOver);
      window.removeEventListener("dragleave", handleWindowDragLeave);
      window.removeEventListener("drop", handleWindowDrop);
    };
  }, []);

  const allowedTypes = allowedFileTypes
    .map((type) => type.toUpperCase().replace(/\./g, ""))
    .join(", ");

  if (!isDragging || isFileUploading) {
    return null;
  }

  return (
    <div
      className={classNames(styles.DragAndDropOverlayStyles, {
        [styles.visible]: isDragging,
        [styles.hidden]: !isDragging,
      })}
    >
      <Dragger
        {...UploadFileProps}
        className={classNames(styles.DragAndDropZoneStyles, {
          [styles.DragOverStyles]: isDragOver,
        })}
        handleDragEnter={handleDragEnter}
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <img
          src="/pdf_expor.svg"
          alt="Drag and Drop"
          className={styles.DragAndDropZoneImageStyles}
        />
        <div className={styles.DragAndDropZoneTextAreaStyles}>
          <div className={styles.DragAndDropZoneHeaderTextStyles}>
            Drop your files here
          </div>
          <p className={styles.DragAndDropZoneTextStyles}>
            Supported formats: {allowedTypes}
          </p>
        </div>
      </Dragger>
    </div>
  );
};

DragAndDropFiles.propTypes = {
  uploadFileList: PropTypes.array.isRequired,
  beforeUpload: PropTypes.func.isRequired,
  handleFileDropped: PropTypes.func.isRequired,
  handleFileRemoveChange: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  isFileUploading: PropTypes.bool.isRequired,
};
