export const MOBILE_VIEW_PIXELS = 430;
export const TYPING_ANIMATION_SPEED = 20;
export const TABLET_VIEW_PIXELS = 768;
export const SMALL_TABLET_VIEW_PIXELS = 640;
export const EVIDENCE_WINDOW_VIEW_PIXELS = 1080;
export const MODEL_OPTIONS = [
  { value: "GPT4TURBO", label: "GPT-4 Turbo" },
  { value: "GPT4", label: "GPT-4" },
  { value: "GPT35", label: "GPT-3.5" },
];
