import { Divider, Tooltip, Layout } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import { BDOButton, ButtonColours } from "../../components/Button";
import { useDrawerContext } from "../../hooks/useDrawerContext";
import { AccountItems } from "../../components/AccountItems";

import styles from "./BDONavbar.module.css";
import { SettingDropdown } from "../../components/SettingDropdown";
import { NewChatIcon, RightSidebarIcon } from "../../Icons";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

export const BDONavbar = ({ clearChat }) => {
  const navigate = useNavigate();
  const {
    setRightsideCollapseStatus,
    isSmallTabletView,
    setShowSettingsDrawer,
  } = useDrawerContext();

  const handleRightSideDrawerClick = () => {
    setRightsideCollapseStatus((val) => !val);
  };

  return (
    <Header className={styles.BDONavbar}>
      <div className={styles.brandingWrapper}>
        <img
          className={styles.personaImage}
          src="persona_logo.svg"
          alt="persona_logo"
        />

        {!isSmallTabletView ? (
          <>
            <h1 className={styles.brandText}>Personas</h1>
            <p className={styles.preview}>Preview</p>
            <Divider type="vertical" className={styles.divider} />
            <Tooltip title="Start a new chat session" placement="bottom">
              <BDOButton
                Icon={<NewChatIcon />}
                size="small"
                className="small-btn"
                variant={ButtonColours.RED_OUTLINED}
                onClick={() => clearChat(true)}
              >
                New chat
              </BDOButton>
            </Tooltip>
          </>
        ) : null}
      </div>
      <div className={styles.iconButtonsSections}>
        {isSmallTabletView ? (
          <>
            <Tooltip title="Start a new chat session" placement="bottom">
              <BDOButton
                Icon={<NewChatIcon className={styles.chatIcon} />}
                className={styles.chatButtonStyles}
                variant={ButtonColours.ICON}
                onClick={() => clearChat(true)}
              ></BDOButton>
            </Tooltip>
            <BDOButton
              Icon={<MenuOutlined />}
              variant={ButtonColours.ICON}
              onClick={() => setShowSettingsDrawer(true)}
            ></BDOButton>
          </>
        ) : (
          <>
            <AccountItems />
            <SettingDropdown />
          </>
        )}

        <BDOButton
          Icon={<RightSidebarIcon style={{ transform: "scaleX(-1)" }} />}
          variant={ButtonColours.ICON}
          onClick={handleRightSideDrawerClick}
        ></BDOButton>
      </div>
    </Header>
  );
};
