import { notification, Tooltip } from "antd";
import classNames from "classnames";
import styles from "./ChatMessage.module.css";
import { useDrawerContext } from "../../hooks/useDrawerContext";
import { CopytOutlinedIcon } from "../../Icons/CopyIcon";
import Markdown from "markdown-to-jsx";
import { ButtonColours } from "../../components/Button";
import { routes } from "../../config/apiRoutes";
import { useGetPDF } from "../../hooks/useGetPDF";
import { useRef } from "react";
import { marked } from "marked";
import { formatMDX, processMessage } from "../../utils";

export const ChatMessage = ({ message, isLastMessage }) => {
  const [api, contextHolder] = notification.useNotification();

  const { isTypewriting, handleSetPdfUrl, showEvidencesDrawer } =
    useDrawerContext();

  const evidenceName = useRef("");

  const { getData: getEvidenceFile } = useGetPDF({
    endpoint: routes.getEvidence,
    onSuccess: (response) => {
      displayPDF(response);
    },
    method: "POST",
  });
  const displayPDF = async (blobContent) => {
    const blob = await blobContent.blob();
    const url = URL.createObjectURL(blob);
    handleSetPdfUrl({ url, name: evidenceName.current }, true);
  };

  const copyText = async (text) => {
    if (typeof text !== "string") {
      console.error("Expected a string but received:", typeof text);
      return;
    }
    openNotification();
    const tempElement = document.createElement("div");
    tempElement.innerHTML = marked(text); // Convert Markdown to HTML

    try {
      const clipboardItem = new ClipboardItem({
        "text/html": new Blob([tempElement.innerHTML], { type: "text/html" }),
        "text/plain": new Blob(
          [tempElement.textContent || tempElement.innerText || ""],
          { type: "text/plain" }
        ),
      });

      await navigator.clipboard.write([clipboardItem]);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const openNotification = () => {
    api.open({
      placement: "bottomRight",
      message: "Copied to clipboard",
      description: "Text has been copied to clipboard.",
      type: "success",
    });
  };
  const { splitBySources, splitBySquareBrackets, noAnswerFound } =
    processMessage(message);

  const getSourceElement = (source) => {
    if (source?.includes(".pdf")) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          role="button"
          className={styles.linkButton}
          variant={ButtonColours.LINK}
          onClick={() => {
            getEvidenceFile({ Name: source });
            evidenceName.current = source;
          }}
        >
          {source}
        </a>
      );
    } else {
      return <p>{source}</p>;
    }
  };

  return (
    <div
      className={`chat-message ${message.role === "assistant" && "chatgpt"}`}
    >
      {contextHolder}
      <div
        style={{ padding: showEvidencesDrawer ? "2rem 1rem" : "2rem 5rem" }}
        className={classNames(
          "chat-message-center flex-row",
          styles.chatWrapper
        )}
      >
        <div className={styles.chatItem}>
          <div
            className="flex-row"
            style={{
              width: "100%",
            }}
          >
            <div className={styles.avatarWrapper}>
              {message.role === "assistant" ? (
                <img
                  src="ai_stars.svg"
                  alt="Logo"
                  style={{
                    height: "21px",
                  }}
                />
              ) : (
                <img
                  src="user_persona.svg"
                  alt="Logo"
                  style={{
                    height: "21px",
                  }}
                />
              )}
            </div>
            <div className="message">
              {message.role !== "assistant" && message.message}
              {message.role === "assistant" && (
                <span>
                  <Markdown>{formatMDX(splitBySources?.[0] ?? "")}</Markdown>
                  {splitBySquareBrackets.length > 1 &&
                    noAnswerFound &&
                    (!isTypewriting || !isLastMessage) && (
                      <>
                        <br />
                        <br />
                        <div>
                          <span>
                            <b>Sources:</b>
                          </span>
                          <ul style={{ listStylePosition: "inherit" }}>
                            {splitBySquareBrackets.map((source, index) => {
                              if (index > 0) {
                                return (
                                  <li key={index}>
                                    {getSourceElement(source)}
                                  </li>
                                );
                              }
                              return null;
                            })}
                          </ul>
                        </div>
                      </>
                    )}
                </span>
              )}
            </div>
            <div
              className="flex-row"
              style={{ alignItems: "flex-start", paddingTop: "6px" }}
            >
              <Tooltip title="Copy to clipboard">
                <div
                  className="flex-row actionNotifications"
                  onClick={() => copyText(message.message)}
                >
                  <CopytOutlinedIcon className={styles.copyButtonStyles} />
                </div>
              </Tooltip>
              {contextHolder}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
