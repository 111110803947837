import { useCallback, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { scopesRequest } from "../config/authConfig";

const baseUrl = `https://${process.env.REACT_APP_API_BASE_URL}/api`;

/**
 * Custom hook to fetch data from an API endpoint.
 * @param {Object} options - The options object.
 * @param {string} options.endpoint - The API endpoint to fetch data from.
 * @param {boolean} options.shouldSkip - Whether to skip fetching data or not.
 * @param {function} options.onSuccess - The function to call after successfully fetching data.
 * @param {function} options.onError - The function to call after unsuccessfully fetching data.
 * @returns {Object} - An object containing the fetched data, loading state, and a refetch function.
 */
export const useGetPDF = ({ endpoint, onSuccess, onError, method = "GET" }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { instance, accounts } = useMsal();

  const getData = useCallback(
    async (body) => {
      const authRequest = {
        scopesRequest,
        account: accounts[0],
      };

      setLoading(true);

      try {
        const tokenResponse = await instance.acquireTokenSilent(authRequest);
        const url = `${baseUrl}/${endpoint}`;

        const response = await fetch(url, {
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenResponse.accessToken}`,
          },
          body: body ? JSON.stringify(body) : undefined,
        });
        setData(response);
        onSuccess?.(response);
      } catch (error) {
        setError(error);
        onError && onError(error);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endpoint, instance, accounts, onError]
  );

  function reset() {
    setData(null);
    setError(null);
  }

  return { data, loading, reset, getData, error };
};
