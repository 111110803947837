export const updatesSorter = (versions) => {
  if (!Array.isArray(versions)) return [];
  const versionCopy = [...versions];
  versionCopy.sort((a, b) => {
    const versionA = a.title.replace("Version ", "").split(".").map(Number);
    const versionB = b.title.replace("Version ", "").split(".").map(Number);

    for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
      const numA = versionA[i] || 0;
      const numB = versionB[i] || 0;

      if (numA !== numB) {
        return numB - numA; // Descending order
      }
    }
    return 0;
  });
  const finalVersionsArray = [];
  versionCopy.forEach((data) => {
    const { isCurrentVersion } = data;
    if (!isCurrentVersion) {
      finalVersionsArray.push(data);
    } else {
      finalVersionsArray.unshift(data);
    }
  });
  return finalVersionsArray;
};
export const extractVersion = (version) => {
  const match = version.match(/\d+(\.\d+)+/);
  return match ? match[0] : null;
};
