import classNames from "classnames";

export const FeedbackIcon = ({ className, onClick }) => {
  return (
    <span onClick={onClick} className={classNames("anticon", className)}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <>
          <path
            d="M14.5849 1.21222C14.6771 0.929261 15.0774 0.929261 15.1697 1.21222L15.8457 3.28541C15.887 3.41198 16.005 3.49763 16.1381 3.49763H18.3234C18.6216 3.49763 18.7453 3.87945 18.5038 4.05433L16.7377 5.33291C16.6295 5.41123 16.5843 5.55041 16.6257 5.67739L17.3007 7.74754C17.393 8.03071 17.0692 8.26668 16.8279 8.09201L15.0576 6.81039C14.95 6.73247 14.8045 6.73247 14.6969 6.81039L12.9266 8.09201C12.6854 8.26668 12.3615 8.03071 12.4539 7.74754L13.1289 5.67739C13.1703 5.55041 13.125 5.41123 13.0168 5.33291L11.2508 4.05433C11.0092 3.87945 11.1329 3.49763 11.4311 3.49763H13.6164C13.7496 3.49763 13.8676 3.41198 13.9088 3.28541L14.5849 1.21222Z"
            fill="#858585"
          />
          <path
            d="M9.27947 4.19084C9.26378 4.17948 9.2449 4.17336 9.22553 4.17336H2.59197C2.54118 4.17336 2.5 4.21454 2.5 4.26534V15.7782C2.5 15.829 2.54118 15.8702 2.59197 15.8702H6.46289C6.51368 15.8702 6.55486 15.9113 6.55486 15.9621V19.4281C6.55486 19.5041 6.64171 19.5473 6.7023 19.5015L11.4815 15.8888C11.4974 15.8767 11.5169 15.8702 11.5369 15.8702H17.0904C17.1412 15.8702 17.1824 15.829 17.1824 15.7782V9.95929C17.1824 9.9298 17.1683 9.90209 17.1444 9.88479L16.0981 9.1273C16.0373 9.08327 15.9522 9.12672 15.9522 9.2018V14.5479C15.9522 14.5987 15.911 14.6399 15.8602 14.6399H11.1242C11.1042 14.6399 11.0848 14.6464 11.0688 14.6585L7.93256 17.0293C7.87197 17.0751 7.78512 17.0319 7.78512 16.9559V14.7319C7.78512 14.6811 7.74394 14.6399 7.69314 14.6399H3.82223C3.77144 14.6399 3.73026 14.5987 3.73026 14.5479V5.4956C3.73026 5.4448 3.77144 5.40362 3.82223 5.40362H10.6708C10.7599 5.40362 10.7969 5.28944 10.7247 5.23715L9.27947 4.19084Z"
            fill="#858585"
          />
          <path
            d="M6.43066 8.82815H13.4332V9.92989H6.43066V8.82815Z"
            fill="#858585"
          />
          <path
            d="M6.43066 11.2156H10.3005V12.3173H6.43066V11.2156Z"
            fill="#858585"
          />
        </>
      </svg>
    </span>
  );
};
