import { useEffect, useState, useRef } from "react";
import { Drawer, Button, Input, Tooltip } from "antd";

import { useDrawerContext } from "../../../hooks/useDrawerContext";
import { routes } from "../../../config/apiRoutes";
import { useMutate } from "../../../hooks/useMutate";
import BDOInput from "../../../components/BDOInput/BDOInput";
import { MAX_CHARACTERS } from "../../../config/constants";

import styles from "./adminAddEditUpdatesDrawer.module.css";
import { BDOButton, ButtonColours } from "../../../components";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { extractVersion } from "../../../utils";
import classNames from "classnames";

const { TextArea } = Input;

export const AdminAddEditUpdatesDrawer = ({
  isOpen,
  setIsOpen,
  handleNotification,
  getUpdates,
  isEditMode,
  editUpdateDetails,
  setEditUpdateDetails,
}) => {
  const featuresSectionRef = useRef();

  const { isMobileView } = useDrawerContext();
  const [featuresListing, setFeaturesListing] = useState([""]);
  const [releaseVersion, setReleaseVersion] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const divElement = featuresSectionRef.current;

    const handleResize = (entries) => {
      for (let entry of entries) {
        if (entry.target === divElement) {
          setHeight(entry.contentRect.height);
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (divElement) resizeObserver.observe(divElement);

    return () => {
      if (divElement) resizeObserver.unobserve(divElement);
    };
  }, [featuresSectionRef.current]);

  const closeDrawer = () => {
    setIsOpen(false);
    setIsloading(false);
    setFeaturesListing([""]);
    setReleaseVersion("");
    setEditUpdateDetails(null);
  };

  const handleError = (error) => {
    const status = error.status;
    if (status === 401) {
      handleNotification("Unauthorised.", false);
    } else handleNotification("Something went wrong.", false);
    setIsloading(false);
  };

  const { mutate: addEditRelease } = useMutate({
    endpoint: routes.adminAddUpdateRelease,
    onSuccess: () => {
      handleNotification(
        isEditMode
          ? "Release updated successfully."
          : "Release added successfully.",
        true
      );
      closeDrawer();
      getUpdates();
    },
    onError: handleError,
  });

  async function handleSubmitAddPersona() {
    const filteredEmptyFeatures = featuresListing?.filter((val) => !!val);
    if (!filteredEmptyFeatures.length) {
      handleNotification("At least one feature note is required", false);
      return;
    }
    setFeaturesListing((val) => val.filter((val) => !!val));
    const formattedFeaturesData = filteredEmptyFeatures.map((description) => ({
      description,
    }));
    setIsloading(true);
    const body = JSON.stringify({
      isCurrentVersion: true,
      id: editUpdateDetails?.id ?? undefined,
      title: "Version " + releaseVersion,
      data: formattedFeaturesData,
    });
    addEditRelease({
      body,
    });
  }

  useEffect(() => {
    if (isEditMode && editUpdateDetails) {
      setReleaseVersion(extractVersion(editUpdateDetails?.title ?? ""));
      const notes = editUpdateDetails?.data.map(
        ({ description }) => description
      );
      setFeaturesListing(notes);
    }
    // eslint-disable-next-line
  }, [isEditMode]);

  useEffect(() => {
    scrollToBottom();
  }, [featuresListing]);

  const handleInputChange = (value, index) => {
    const featuresCopy = [...featuresListing];
    featuresCopy[index] = value;
    setFeaturesListing(featuresCopy);
  };
  const handleAddAnotherReleaseNote = () => {
    setFeaturesListing((val) => [...val, ""]);
  };
  const handleRemoveFeature = (index) => {
    setFeaturesListing((val) => val.filter((_, i) => i !== index));
  };
  const handleVersionChange = (e) => {
    const inputValue = e.target.value;
    if (/^[\d.]*$/.test(inputValue)) {
      setReleaseVersion(inputValue);
    }
  };
  const scrollToBottom = () => {
    if (featuresSectionRef.current) {
      featuresSectionRef.current.scrollTo({
        top: featuresSectionRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  return (
    <Drawer
      title={isEditMode ? "Update release notes" : "Create a new release"}
      placement="right"
      onClose={closeDrawer}
      width={isMobileView ? "100%" : 420}
      open={isOpen}
      className={styles.adminAddEditUpdateStyles}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.updatesInputWrapper}>
          <BDOInput
            inputStyles={styles.releaseVersionInputStyles}
            label={"Release version"}
            value={releaseVersion}
            placeholder="Enter version here."
            onChange={handleVersionChange}
            maxLength={MAX_CHARACTERS}
            showDescription={releaseVersion?.length}
            description={
              <>
                Will be displayed as "
                <strong>Version {releaseVersion} (Latest)</strong>"
              </>
            }
          />
          <label className={styles.versionInputFieldPrefixStyles}>
            Version
          </label>
        </div>
        <div>
          <p className={styles.infoLabel}>Release notes</p>

          <div
            className={classNames(styles.adminFeatureListingWrapper, {
              [styles.adminFeatureListingWrapperOverflowAuto]: height > 420,
              [styles.adminFeatureListingWrapperOverflowHidden]: height <= 420,
            })}
            ref={featuresSectionRef}
          >
            {featuresListing?.map((text, index) => (
              <div key={index} className={styles.adminFeatureListItemStyles}>
                <div className={styles.itemContentWrapper}>
                  <TextArea
                    value={text}
                    className={styles.adminFeaturesTextareaStyles}
                    placeholder="Enter note here."
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    maxLength={MAX_CHARACTERS}
                    autoSize={{ minRows: 1, maxRows: 4 }}
                  />
                  {featuresListing.length > 1 ? (
                    <Tooltip title="Remove release note">
                      <BDOButton
                        className={styles.adminRemoveFeatureButton}
                        Icon={<MinusCircleOutlined />}
                        onClick={() => handleRemoveFeature(index)}
                        variant={ButtonColours.ICON}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
          <BDOButton
            onClick={handleAddAnotherReleaseNote}
            Icon={<PlusOutlined />}
            variant={ButtonColours.LINK}
          >
            Add another note
          </BDOButton>
        </div>
      </div>
      <Button
        shape="square"
        type="primary"
        onClick={handleSubmitAddPersona}
        loading={isLoading}
        disabled={!featuresListing?.length || !releaseVersion}
        className={styles.submitButton}
      >
        {isEditMode ? "Update" : "Submit"}
      </Button>
    </Drawer>
  );
};
