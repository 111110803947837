import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Spin } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { SortIcon } from "../../../Icons";
import { BDOButton, BDOTable, ButtonColours, Modal } from "../../../components";
import { paginationOptions } from "../../../config/constants";
import { useGet, useMutate } from "../../../hooks";
import { routes } from "../../../config/apiRoutes";
import {
  addBreaksAfterPoints,
  sorterFunction,
  truncateText,
} from "../../../utils";
import { AdminAddEditPersona } from "../AdminAddEditPersonaDrawer";

import styles from "./personasListing.module.css";
import Markdown from "markdown-to-jsx";

const ALLOWED_DESCRIPTION_LENGTH = 210;

export const PersonasListing = forwardRef(({ handleNotification }, ref) => {
  const [personas, setPersonas] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [viewDeleteConfirmationModal, setViewDeleteConfirmationModal] =
    useState(false);
  const [viewPerosnaDetailsModal, setViewPerosnaDetailsModal] = useState(false);
  const [loadingDeletePersona, setLoadingDeletePersona] = useState(false);
  const [showAddEditPersonaDrawer, setShowAddEditPersonaDrawer] =
    useState(false);
  const [viewPersonaDetails, setViewPersonaDetails] = useState({});
  const [deletePersonaDetails, setDeletePersonaDetails] = useState({});
  const [editPersonaDetails, setEditPersonaDetails] = useState(null);

  const { refetch: getPersonas } = useGet({
    endpoint: `${routes.getBDOPersonas}?ProjectName=BDO-Personas`,
    onSuccess: (data) => {
      setPersonas(data?.data?.personas ?? []);
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const handleDeletePersonaError = (error) => {
    const status = error.status;
    if (status === 401) {
      handleNotification("Unauthorised.", false);
    } else handleNotification("Something went wrong.", false);
    setLoadingDeletePersona(false);
    setViewDeleteConfirmationModal(false);
  };
  const { mutate: deletePersona } = useMutate({
    endpoint: routes.adminDeletePersona,
    method: "DELETE",
    onSuccess: async () => {
      setViewDeleteConfirmationModal(false);
      setLoadingDeletePersona(false);
      handleNotification("Persona deleted successfully.", true);
      setIsLoading(true);
      getPersonas();
      setDeletePersonaDetails({});
    },
    onError: handleDeletePersonaError,
  });

  useImperativeHandle(ref, () => ({
    handleAddPersona: () => {
      setShowAddEditPersonaDrawer(true);
    },
  }));

  const handleViewMorePersonaDetails = (data) => {
    setViewPerosnaDetailsModal(true);
    setViewPersonaDetails(data);
  };
  const handleCancelPersonaDetailsModal = () => {
    setViewPerosnaDetailsModal(false);
    setViewPersonaDetails({});
  };
  const dashboardTableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "25%",
      key: "name",
      sortIcon: (data) => <SortIcon sortOrder={data.sortOrder} />,
      sorter: (a, b) => sorterFunction(a.name, b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "55%",
      key: "description",
      sortIcon: (data) => <SortIcon sortOrder={data.sortOrder} />,
      sorter: (a, b) => sorterFunction(a.description, b.description),

      render: (description, data, index) => (
        <p className={styles.descriptionStyles}>
          {truncateText(
            description ? description : "-",
            ALLOWED_DESCRIPTION_LENGTH
          )}
          {description?.length > ALLOWED_DESCRIPTION_LENGTH ? (
            <>
              <br />
              <BDOButton
                variant={ButtonColours.LINK}
                onClick={() => handleViewMorePersonaDetails(data)}
              >
                <div className={styles.downloadConversationWrapper}>
                  {" "}
                  read more
                </div>
              </BDOButton>
            </>
          ) : null}
        </p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      width: "20%",
      key: "name",
      render: (data) => (
        <div className={styles.actionItemsContainer}>
          <BDOButton
            className={styles.actionItemsStyles}
            onClick={() => handleEditPersonaClick(data)}
            Icon={<EditOutlined />}
            variant={ButtonColours.ICON}
          ></BDOButton>
          <BDOButton
            onClick={() => {
              handleDeletePersona(data);
            }}
            className={styles.actionItemsStyles}
            Icon={<DeleteOutlined />}
            variant={ButtonColours.ICON}
          ></BDOButton>
        </div>
      ),
    },
  ];

  const handleCancelConversationModal = () => {
    setViewDeleteConfirmationModal(false);
  };
  const handleDeletePersonaSubmit = () => {
    setLoadingDeletePersona(true);
    deletePersona({
      body: JSON.stringify({ name: deletePersonaDetails.name }),
    });
  };

  const handleDeletePersona = (data) => {
    setDeletePersonaDetails(data);
    setViewDeleteConfirmationModal(true);
  };

  const handleEditPersonaClick = ({ name, description }) => {
    setEditPersonaDetails({ name, description });
    setShowAddEditPersonaDrawer(true);
  };

  return (
    <>
      <Spin
        spinning={loadingDeletePersona}
        indicator={
          <LoadingOutlined spin className={styles.tableLoaderStyles} />
        }
        size="large"
        fullscreen
      />
      <AdminAddEditPersona
        handleNotification={handleNotification}
        isOpen={showAddEditPersonaDrawer}
        getPersonas={getPersonas}
        setIsOpen={setShowAddEditPersonaDrawer}
        isEditMode={Boolean(editPersonaDetails)}
        editPersonaDetails={editPersonaDetails}
        setEditPersonaDetails={setEditPersonaDetails}
      />
      <div className={styles.tableWrapper}>
        <Modal
          width={740}
          isModalOpen={viewPerosnaDetailsModal}
          setIsModalOpen={setViewPerosnaDetailsModal}
          viewActions={false}
          handleCancel={handleCancelPersonaDetailsModal}
          title={viewPersonaDetails.name}
          subTitle={
            <Markdown>
              {addBreaksAfterPoints(viewPersonaDetails.description)}
            </Markdown>
          }
          handleSubmit={handleDeletePersonaSubmit}
          isLoading={loadingDeletePersona}
        ></Modal>
        <Modal
          isModalOpen={viewDeleteConfirmationModal}
          setIsModalOpen={setViewDeleteConfirmationModal}
          handleCancel={handleCancelConversationModal}
          submitButtomText="Confirm"
          title="Delete Persona"
          subTitle={
            <span>
              Are you sure you want to delete{" "}
              <strong>{deletePersonaDetails.name}</strong>?
            </span>
          }
          handleSubmit={handleDeletePersonaSubmit}
          isLoading={loadingDeletePersona}
        ></Modal>
        <BDOTable
          loading={{
            spinning: isLoading,
            indicator: (
              <Spin
                indicator={
                  <LoadingOutlined spin className={styles.tableLoaderStyles} />
                }
                size="large"
              />
            ),
          }}
          dataSource={personas}
          columns={dashboardTableColumns}
          pagination={paginationOptions}
          scroll={{ y: 76 * 4 }}
        />
      </div>
    </>
  );
});
