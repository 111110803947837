import React from "react";
import { Input } from "antd";

import styles from "./bdoInput.module.css";
import classNames from "classnames";

const BDOInput = ({
  label,
  inputWrapperStyles = "",
  inputStyles = "",
  description = "",
  showDescription = true,
  ...props
}) => {
  return (
    <div className={classNames(styles.inputWrapper, inputWrapperStyles)}>
      {label && <label>{label}</label>}
      <Input
        {...props}
        className={classNames(styles.inputStyles, inputStyles)}
      />
      {description && showDescription ? (
        <p className={styles.inputInfoStyles}>{description}</p>
      ) : null}
    </div>
  );
};

export default BDOInput;
