import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Collapse, Spin } from "antd";
import { EditOutlined, LoadingOutlined, UpOutlined } from "@ant-design/icons";

import { BDOButton, ButtonColours } from "../../../components";
import { useGet } from "../../../hooks";
import { routes } from "../../../config/apiRoutes";
import { updatesSorter } from "../../../utils";

import styles from "./updatesListing.module.css";
import classNames from "classnames";
import { AdminAddEditUpdatesDrawer } from "../AdminAddEditUpdates";

const Panel = Collapse.Panel;

export const UpdatesListing = forwardRef(({ handleNotification }, ref) => {
  const [updates, setUpdates] = useState([]);
  const [activeKey, setActiveKey] = useState([]);
  const [isLoadingGetUpdates, setIsLoadingGetUpdates] = useState(true);
  const [showAddEditPersonaUpdates, setShowAddEditPersonaUpdates] =
    useState(false);
  const [editUpdateDetails, setEditUpdateDetails] = useState(null);

  const { refetch: refetchUpdates } = useGet({
    endpoint: routes.adminGetUpdates,
    onSuccess: (resUpdates) => {
      setIsLoadingGetUpdates(false);
      setUpdates(updatesSorter(resUpdates));
    },
    onError: () => {
      setIsLoadingGetUpdates(false);
    },
  });

  useImperativeHandle(ref, () => ({
    handleUpdateRelease: () => {
      setShowAddEditPersonaUpdates(true);
    },
  }));
  const handleEditUpdates = (data) => {
    setEditUpdateDetails(data);
    setShowAddEditPersonaUpdates(true);
  };
  return (
    <div className={styles.updatesListingMainWrapperStyles}>
      <AdminAddEditUpdatesDrawer
        handleNotification={handleNotification}
        isOpen={showAddEditPersonaUpdates}
        getUpdates={refetchUpdates}
        setIsOpen={setShowAddEditPersonaUpdates}
        isEditMode={Boolean(editUpdateDetails)}
        editUpdateDetails={editUpdateDetails}
        setEditUpdateDetails={setEditUpdateDetails}
      />
      <Spin
        spinning={isLoadingGetUpdates}
        indicator={
          <LoadingOutlined spin className={styles.tableLoaderStyles} />
        }
        size="large"
        fullscreen
      />
      <div className={styles.adminUpdatesListingWrapper}>
        <Collapse
          size="large"
          accordion
          expandIconPosition="right"
          activeKey={activeKey}
          onChange={setActiveKey}
          expandIcon={({ isActive }) => (
            <UpOutlined rotate={isActive ? 0 : 180} />
          )}
        >
          {updates.map(({ title, data, isCurrentVersion, id }, index) => (
            <Panel
              className={classNames(styles.adminUpdatesPanel)}
              header={
                <div className={styles.accordionHeaderWrapper}>
                  <span>
                    {index !== 0 ? title : <strong>{title} (Latest)</strong>}
                  </span>
                  {isCurrentVersion ? (
                    <BDOButton
                      className={styles.editButtonStyles}
                      variant={ButtonColours.LINK}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditUpdates({
                          title,
                          data,
                          isCurrentVersion,
                          id,
                        });
                      }}
                    >
                      <div className={styles.downloadConversationWrapper}>
                        {" "}
                        <EditOutlined /> Edit{" "}
                      </div>
                    </BDOButton>
                  ) : null}
                </div>
              }
              key={index + 1}
            >
              <ol type="1" className={styles.listStyles}>
                {data?.map((details) => (
                  <li
                    key={details.description}
                    dangerouslySetInnerHTML={{
                      __html: details.description,
                    }}
                  />
                ))}
              </ol>
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
});
