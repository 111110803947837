import { Image } from "antd";
import React from "react";
import { imageFileRegex } from "../../config/constants";
import { getImageByFileType } from "../../utils";
import { SpinnerLoader } from "../SpinnerLoader";

import styles from "./fileLoaderPreview.module.css";

export const FileLoaderPreview = ({ uploadedFile }) => {
  return (
    <div className={styles.tagPlaceholderItemsWrapper}>
      <div className={styles.tagWrapper}>
        <div className={styles.fileUploadingOverlayLoaderStyles}>
          <SpinnerLoader>
            <div
              className={
                imageFileRegex.test(uploadedFile?.name)
                  ? styles.previewImageStyles
                  : styles.previewImageFileStyles
              }
            >
              <div className={styles.filePreviewStyles}>
                {imageFileRegex.test(uploadedFile?.name) ? (
                  <Image
                    className={styles.previewImageStyles}
                    src={URL.createObjectURL(uploadedFile)}
                    alt={uploadedFile?.name}
                    preview={false}
                  />
                ) : (
                  <Image
                    src={getImageByFileType(uploadedFile)}
                    alt={getImageByFileType(uploadedFile)
                      ?.replaceAll(".jpg", "")
                      ?.replaceAll("/", "")}
                    preview={false}
                  />
                )}
              </div>
            </div>
          </SpinnerLoader>
        </div>
      </div>
    </div>
  );
};

export default FileLoaderPreview;
