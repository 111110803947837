import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { scopesRequest } from "../config/authConfig";

const baseUrl = `https://${process.env.REACT_APP_API_BASE_URL}/api`;

/**
 * Custom hook to mutate data from an API endpoint.
 * @param {Object} options - The options object.
 * @param {string} options.endpoint - The API endpoint to mutate data from.
 * @param {string} options.method - The HTTP method to use.
 * @param {Function} options.onSuccess - The function to call on success.
 * @param {Function} options.onError - The function to call on error.
 * @returns {Object} - An object containing the mutate function, loading state, and error state.
 */
export function useMutate({ endpoint, method = "POST", onSuccess, onError }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { instance, accounts } = useMsal();

  async function mutate({ body }) {
    const authRequest = {
      scopesRequest,
      account: accounts[0],
    };

    setError(null);
    setLoading(true);

    try {
      const tokenResponse = await instance.acquireTokenSilent(authRequest);
      const url = `${baseUrl}/${endpoint}`;

      const headers = {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        AppVersion: localStorage.getItem("appVersion"),
      };

      if (typeof body === "string") {
        headers["Content-Type"] = "application/json";
      }

      const response = await fetch(url, { method, headers, body });

      setLoading(false);

      if (response.ok) {
        if (onSuccess) {
          onSuccess(response);
        }

        return response;
      } else {
        if (onError) {
          onError(error);
        }
        setError(response);

        return response;
      }
    } catch (error) {
      console.log(error);
      if (onError) {
        onError(error);
      }
      setError(error);
      setLoading(false);
    }
  }

  return { mutate, loading, error };
}
