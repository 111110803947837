import { Layout } from "antd";
import { Footer } from "../modules/Footer";
import { NewUpdatesDrawer } from "../modules/NewUpdatesDrawer";
import { ShareFeedbackDrawer } from "../modules/ShareFeedbackDrawer";
import { BDONavbar, AdminNavbar } from "../modules/BDONavbar";
import { useDrawerContext } from "../hooks/useDrawerContext";
import { useLocation } from "react-router-dom";
import { SettingsDrawer } from "../modules/SettingsDrawer";

import styles from "./MainLayout.module.css";

const { Content } = Layout;
export const MainLayout = ({ children, clearChat }) => {
  const location = useLocation();
  const { isTabletView, isMobileView } = useDrawerContext();

  const getNavbarByLayout = () => {
    if (location.pathname.includes("admin")) {
      return <AdminNavbar clearChat={clearChat} />;
    }
    return <BDONavbar clearChat={clearChat} />;
  };

  return (
    <div className={styles.mainLayout}>
      {getNavbarByLayout()}
      <Content className={styles.layoutContent}>{children}</Content>
      {!isTabletView && !isMobileView ? <Footer /> : null}
      <ShareFeedbackDrawer />
      <NewUpdatesDrawer />
      <SettingsDrawer />
    </div>
  );
};
