import { Popover } from "antd";
import styles from "./popeover.module.css";
import { useConfigContext } from "../../hooks/useConfigContext";

const Content = () => {
  const { config } = useConfigContext();
  return (
    <div className={styles.contentWrapper}>
      <h1 className={styles.mainHeading}>Upload a file or image</h1>
      <p className={styles.textStyles}>
        {config?.MaxFileUploadLimit > 1 && (
          <>
            Upload file(s) (<strong>Max: {config?.MaxFileUploadLimit}</strong>)
            and chat with them.
            <br />
          </>
        )}
        This includes .pdf, .docx, .xlsx, .txt up to the size of 20MB and images
        up to 10MB.
      </p>
    </div>
  );
};

export const UploadFilePopover = ({
  children,
  title,
  uploadProps,
  showFileUploadButton,
}) => {
  return (
    <Popover
      placement="topLeft"
      title={title}
      content={
        showFileUploadButton ? <Content uploadProps={uploadProps} /> : null
      }
    >
      {children}
    </Popover>
  );
};
