import { Layout } from "antd";

import styles from "./BDONavbar.module.css";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

export const AdminNavbar = () => {
  const navigate = useNavigate();
  return (
    <Header className={styles.BDONavbar}>
      <div className={styles.brandingWrapper}>
        <img
          onClick={() => navigate("/")}
          className={styles.adminPersonaImage}
          src="persona_logo.svg"
          alt="persona_logo"
        />
        <h1 className={styles.brandText}>Personas</h1>
        <p className={styles.preview}>Preview</p>
      </div>
    </Header>
  );
};
