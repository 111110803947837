import { Button } from "antd";
import classNames from "classnames";

import styles from "./Button.module.css";

export const ButtonColours = Object.freeze({
  RED_OUTLINED: Symbol("red-outlined"),
  ICON: Symbol("icon"),
  TEXT_ICON: Symbol("text-icon"),
  LINK: Symbol("link"),
  RED: Symbol("red"),
  DEFAULT: Symbol("default"),
});

export const BDOButton = ({
  children,
  Icon,
  variant,
  className = "",
  hasMinWidth = false,
  ...rest
}) => {
  const buttonClassName = classNames(
    styles.BDOButton,
    {
      [styles.bdoRedOutlined]: variant === ButtonColours.RED_OUTLINED,
      [styles.iconButton]: variant === ButtonColours.ICON,
      [styles.textIconButton]: variant === ButtonColours.TEXT_ICON,
      [styles.linkButton]: variant === ButtonColours.LINK,
      [styles.bdoRed]: variant === ButtonColours.RED,
      [styles.defaultButton]: variant === ButtonColours.DEFAULT,
      [styles.hasMinWidth]: hasMinWidth,
    },
    className
  );
  return (
    <Button className={buttonClassName} icon={Icon} {...rest}>
      {children}
    </Button>
  );
};
