export const routes = {
  addUserConsent: "User/consent",
  addUserToADGroup: "<not-implemented>",
  addUserToWaitingList: "User/add/waiting",
  removeUsersFromWaitingList: "User/send/waiting/list",
  savePersona: "Personas",
  updatePersona: "Personas/description",
  deletePersona: "Personas/remove/persona",
  exportConversation: "Transformer/generatePDFFromChatConversation",
  callGPTStreamApi: "Transformer/request",
  chatWithFileStream: "Transformer/request/chat",
  clearUserData: "User/clear/files",
  emailFeedback: "User/feedback",
  getBDOPersonas: "Personas/BDO",
  getBDOVoiceTokenCount: "Transformer/tokencount",
  getUserMembership: "User/membership",
  getUserProfile: "User/profile",
  getWaitingList: "User/get/waiting/list",
  uploadFiles: "Transformer/file/upload",
  getStyleGuide: "Personas/writingstyleguide",
  getEvidence: "Transformer/file/download",
  getConfigurations: "Admin/getAllUIConfigurations",
  markNotificationasRead: "User/newUpdates",
  checkIfUserIsAdmin: "Admin/isAdminUser",
  adminDeletePersona: "Personas/DeleteBDOPersona",
  adminAddPersona: "Personas/AddBDOPersona",
  adminUpdatePersona: "Personas/UpdateBDOPersona",
  adminGetUpdates: "Admin/getVersionUpdates",
  adminAddUpdateRelease: "Admin/AddOrUpdateVersionUpdates",
};
