import { useEffect, useState } from "react";
import { Drawer, Button } from "antd";
import classNames from "classnames";

import { useDrawerContext } from "../../../hooks/useDrawerContext";
import { routes } from "../../../config/apiRoutes";
import { useMutate } from "../../../hooks/useMutate";
import BDOInput from "../../../components/BDOInput/BDOInput";

import styles from "./adminAddPersonaDrawer.module.css";
import { MAX_CHARACTERS } from "../../../config/constants";

export const AdminAddEditPersona = ({
  isOpen,
  setIsOpen,
  handleNotification,
  getPersonas,
  isEditMode,
  editPersonaDetails,
  setEditPersonaDetails,
}) => {
  const { isMobileView } = useDrawerContext();
  const [personaDetails, setPersonaDetails] = useState("");
  const [personaName, setPersonaName] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const closeDrawer = () => {
    setIsOpen(false);
    setIsloading(false);
    setPersonaDetails("");
    setPersonaName("");
    setEditPersonaDetails(null);
  };

  const handleError = (error) => {
    const status = error.status;
    if (status === 401) {
      handleNotification("Unauthorised.", false);
    } else handleNotification("Something went wrong.", false);
    setIsloading(false);
  };

  const { mutate: addPersona } = useMutate({
    endpoint: routes.adminAddPersona,
    onSuccess: async (res) => {
      const resData = await res.json();
      const { isSuccess, resultMessage } = resData || {};
      if (!isSuccess) {
        handleNotification(resultMessage, false);
        setIsloading(false);
        return;
      }
      handleNotification("Persona added successfully.", true);
      closeDrawer();
      getPersonas();
    },
    onError: handleError,
  });
  const { mutate: updatePersona } = useMutate({
    endpoint: routes.adminUpdatePersona,
    method: "PATCH",
    onSuccess: () => {
      handleNotification("Persona updated successfully.", true);
      closeDrawer();
      getPersonas();
    },
    onError: handleError,
  });

  async function handleSubmitAddPersona() {
    setIsloading(true);
    if (isEditMode) {
      updatePersona({
        body: JSON.stringify({
          name: personaName,
          description: personaDetails,
        }),
      });
    } else {
      addPersona({
        body: JSON.stringify({
          name: personaName,
          description: personaDetails,
        }),
      });
    }
  }

  useEffect(() => {
    if (isEditMode) {
      setPersonaName(editPersonaDetails.name);
      setPersonaDetails(editPersonaDetails.description);
    }
  }, [isEditMode]);

  return (
    <Drawer
      title={isEditMode ? "Update Persona" : "Add Persona"}
      placement="right"
      onClose={closeDrawer}
      width={isMobileView ? "100%" : 520}
      open={isOpen}
      className={styles.addPersona}
    >
      <div className={styles.contentWrapper}>
        <BDOInput
          label={"Persona name"}
          value={personaName}
          placeholder="Enter persona name here."
          disabled={isEditMode}
          onChange={(e) => setPersonaName(e.target.value)}
          showCount={!isEditMode}
          maxLength={MAX_CHARACTERS}
          inputStyles={styles.perosnaEditInputStyles}
        />
        <div>
          <p className={styles.infoLabel}>Persona details.</p>
          <textarea
            placeholder="Enter persona description here."
            className={classNames(
              "noResize feedbackInput mb-4",
              styles.addPersonaTextAreaInputStyles
            )}
            value={personaDetails}
            id="feedback"
            onChange={(e) => setPersonaDetails(e.target.value)}
          ></textarea>
        </div>
        <div className="flex-row" style={{ justifyContent: "flex-end" }}>
          <Button
            shape="square"
            type="primary"
            onClick={handleSubmitAddPersona}
            loading={isLoading}
            disabled={!personaDetails?.trim() || !personaName?.trim()}
            className={styles.submitButton}
          >
            {isEditMode ? "Update" : "Submit"}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
