import React from "react";
import { Tooltip } from "antd";
import {
  AudioMutedOutlined,
  AudioOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import styles from "./VoiceInput.module.css";
import { VoicePopover } from "../Popeovers";

export const VoiceInput = ({
  listening,
  handleStartListening,
  onStopListening,
  isLoading,
}) => {
  const cursorStyle = isLoading ? "not-allowed" : "pointer";
  const shouldShowTooltip = cursorStyle !== "not-allowed";

  return (
    <>
      {!listening && (
        <>
          {shouldShowTooltip ? (
            <VoicePopover
              title="Start voice input"
              description="Talk to Personas: convert speech to text."
            >
              <AudioOutlined
                onClick={() => {
                  if (!isLoading) handleStartListening();
                }}
                className={styles.audioActiveIcon}
                disabled={isLoading}
                style={{
                  cursor: cursorStyle,
                  color: isLoading
                    ? "var(--bdo-charcoal-light)"
                    : "var(--sub-text)",
                }}
              />
            </VoicePopover>
          ) : (
            <AudioOutlined
              onClick={() => {
                if (!isLoading) handleStartListening();
              }}
              className={styles.audioActiveIcon}
              disabled={isLoading}
              style={{
                cursor: cursorStyle,
                color: isLoading
                  ? "var(--bdo-charcoal-light)"
                  : "var(--sub-text)",
              }}
            />
          )}
        </>
      )}
      {listening && (
        <VoicePopover
          title="Stop voice input"
          description="Stop voice dictation."
        >
          <AudioMutedOutlined
            className={styles.audioMutedIcon}
            onClick={onStopListening}
            style={{
              color: "var(--bdo-primary)", // Set icon color to --bdo-primary
              cursor: "pointer",
            }}
          />
        </VoicePopover>
      )}
    </>
  );
};
