import React, { createContext, useState, useEffect } from "react";
import { routes } from "../config/apiRoutes";
import { useMutate } from "../hooks/useMutate";

export const ConfigContext = createContext({});

export function ConfigContextProvider({ children }) {
  const [config, setConfig] = useState();
  const [hasNewVersion, setHasNewVersion] = useState(false);

  const { mutate: getConfigurations } = useMutate({
    endpoint: routes.getConfigurations,
    method: "GET",
    onSuccess: async (response) => {
      const data = await response.json();
      setConfig(data);
    },
  });

  useEffect(() => {
    getConfigurations({});
  }, []);
  return (
    <ConfigContext.Provider value={{ config, hasNewVersion, setHasNewVersion }}>
      {children}
    </ConfigContext.Provider>
  );
}
