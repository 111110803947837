import { Popover } from "antd";
import styles from "./popeover.module.css";

const Content = ({ title, description }) => {
  return (
    <div className={styles.contentWrapper}>
      <h1 className={styles.mainHeading}>{title}</h1>
      <p className={styles.textStyles}>{description}</p>
    </div>
  );
};

export const VoicePopover = ({ children, title, description }) => {
  return (
    <Popover
      placement="topLeft"
      content={<Content title={title} description={description} />}
    >
      {children}
    </Popover>
  );
};
