export const processMessage = (message) => {
  const splitBySources =
    message.message != null &&
    message.message.split("SOURCES:").map((item) => item.trim());

  const splitBySquareBrackets =
    splitBySources?.length > 1 && splitBySources[1].split(",");

  const noAnswerFound = splitBySources?.[0]?.search("I'm sorry");

  return { splitBySources, splitBySquareBrackets, noAnswerFound };
};
