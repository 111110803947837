import React, { useRef, useState } from "react";
import { Layout, notification, Tabs } from "antd";
import { useNavigate } from "react-router-dom";

import { useGet } from "../../hooks";
import { routes } from "../../config/apiRoutes";

import styles from "./adminPanel.module.css";
import { BDOButton, ButtonColours } from "../../components";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { PersonasListing } from "../../modules/AdminModules/PersonasListing/PersonasListing";
import { UpdatesListing } from "../../modules/AdminModules/UpdatesListing/UpdatesListing";

const { Content } = Layout;

export const AdminPage = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const personasListingRef = useRef();
  const updatesListingRef = useRef();

  const [tabIndex, setTabIndex] = useState("1");

  useGet({
    endpoint: routes.checkIfUserIsAdmin,
    onSuccess: (isAdmin) => {
      if (!isAdmin) navigate("/");
    },
  });

  const handleNotification = (message, isSuccess, description) => {
    api.info({
      placement: "bottomRight",
      message,
      icon: isSuccess ? (
        <CheckCircleFilled className={styles.iconCheck} />
      ) : (
        <CloseCircleOutlined className={styles.iconClose} />
      ),
      duration: 3,
      description,
    });
  };

  const AdminPanelTabs = [
    {
      key: "1",
      label: "Personas",
      children: (
        <PersonasListing
          ref={personasListingRef}
          handleNotification={handleNotification}
        />
      ),
    },
    {
      key: "2",
      label: "Releases",
      children: (
        <UpdatesListing
          ref={updatesListingRef}
          handleNotification={handleNotification}
        />
      ),
    },
  ];
  const handleAddItem = () => {
    if (tabIndex === "1") {
      personasListingRef.current.handleAddPersona();
    } else {
      updatesListingRef.current.handleUpdateRelease();
    }
  };
  return (
    <Content className={styles.contentWrapper}>
      {contextHolder}
      <div className={styles.dashboardWrapper}>
        <h3 className={styles.pageHeaderStyles}>
          Manage personas and releases
        </h3>
        <Tabs
          defaultActiveKey="1"
          items={AdminPanelTabs}
          tabIndex={tabIndex}
          className={styles.adminPanelTabs}
          onTabClick={(data) => {
            setTabIndex(data);
          }}
          tabBarExtraContent={
            <BDOButton
              Icon={<PlusOutlined />}
              className={styles.tabActionButtons}
              variant={ButtonColours.RED_OUTLINED}
              onClick={handleAddItem}
            >
              {tabIndex === "1" ? "Add Persona" : "Create release version"}
            </BDOButton>
          }
        />
      </div>
    </Content>
  );
};
