import Markdown from "markdown-to-jsx";
import ReactDOMServer from "react-dom/server";
import { v4 as guid } from "uuid";

const maxSizeBytes = 20 * 1024 * 1024; // 20 MB
const maxImageSizeBytes = 10 * 1024 * 1024; // 10 MB
const allowedDocTypes = ["pdf", "docx", "xlsx", "txt"];
const allowedImageTypes = ["png", "jpeg", "jpg"];
const maxResolution = 20 * 1_000 * 1_000; // 20 megapixels

export const checkFileUpload = async (
  file,
  setErrorMessage,
  uploadFileList
) => {
  const fileType = file.name.split(".").pop().toLowerCase();
  const isDocument = allowedDocTypes.includes(fileType);
  const isImage = allowedImageTypes.includes(fileType);

  if (checkExistingFileName(file, uploadFileList)) {
    setErrorMessage({
      isError: true,
      message: "File with the same name has already been uploaded!",
    });
    return false;
  }
  if (isDocument) {
    if (file.size > maxSizeBytes) {
      setErrorMessage({
        isError: true,
        message:
          "The file exceeds the maximum file size. Max limit allowed (20 MB).",
      });
      return false;
    }
  } else if (isImage) {
    if (file.size > maxImageSizeBytes) {
      setErrorMessage({
        isError: true,
        message:
          "The image exceeds the maximum allowed resolution. Max limit allowed (10 MB).",
      });
      return false;
    }
    const hasCorrectResolution = await checkImageResolution(
      file,
      setErrorMessage
    );
    if (!hasCorrectResolution) {
      return false;
    }
  } else {
    setErrorMessage({
      isError: true,
      message:
        "Invalid file type. You can only upload .pdf, .text, .txt, .docx, .xlsx, .png, .jpg and .jpeg files",
    });

    return false;
  }

  return true;
};

// Helper function to check for existing file names
const checkExistingFileName = (file, uploadFileList) => {
  return uploadFileList.some((f) => f.name === file.name);
};

const checkImageResolution = async (file, setErrorMessage) => {
  const response = await new Promise(async (resolve) => {
    const image = await new Image();
    image.src = await URL.createObjectURL(file);

    image.onload = () => {
      const resolution = image.width * image.height;
      let isError = false;

      if (resolution > maxResolution) {
        isError = true;
        setErrorMessage({
          isError: true,
          message:
            "The image exceeds the maximum image resolution. Max limit allowed (20 Megapixels).",
        });
      }
      resolve(isError);
    };

    image.onerror = () => {
      // Handle error in case the image can't load
      setErrorMessage({
        isError: true,
        message: "Failed to load the image.",
      });
      resolve(true);
    };
  });
  return !response;
};

export const getCurrentDateAndTime = () => {
  const date = new Date(Date.now());
  return date.toISOString();
};

export const formatMDX = (input) => {
  return input.replace(/\B#(?=\w)/g, "\\#");
};
export const removeSourcesFromChatLog = (chatLog) => {
  if (Array.isArray(chatLog)) {
    return chatLog.map((data) => {
      return {
        ...data,
        message: data.message.replace(/SOURCES:[\s\S]*/, "").trim(),
      };
    });
  } else return chatLog;
};
export const updateCurrentChat = (chat, model) => {
  if (chat.length === 0 || !model) return chat;
  const lastMessage = chat[chat.length - 1];
  if (lastMessage.model) return chat;
  const updatedLastMessage = { ...lastMessage, model };
  return [...chat.slice(0, -1), updatedLastMessage];
};
function markdownToHtml(markdown) {
  const removedSourcesText = markdown.replace(/SOURCES:[\s\S]*/, "").trim();
  const jsxContent = <Markdown>{formatMDX(removedSourcesText)}</Markdown>;

  const stringContent = ReactDOMServer.renderToStaticMarkup(jsxContent);

  return stringContent;
}

export const exportConversationFactory = (
  role,
  message,
  personaName,
  personaText,
  isBDOVoice,
  model = ""
) => {
  return {
    role,
    message,
    markdown: markdownToHtml(message),
    timeStamp: getCurrentDateAndTime(),
    personaName,
    personaText,
    isBDOVoice,
    model,
  };
};

export const generateGUID = () => {
  return guid();
};
export function truncateText(str, maxChars) {
  if (str.length <= maxChars) {
    return str;
  }

  let truncated = str.slice(0, maxChars);
  let lastSpaceIndex = truncated.lastIndexOf(" ");

  if (lastSpaceIndex > 0) {
    truncated = truncated.slice(0, lastSpaceIndex);
  }

  return `${truncated}...`;
}

export const sorterFunction = (a, b) => {
  if (!a || !b) return 0; // Handle undefined cases explicitly
  return a.localeCompare(b);
};

export function addBreaksAfterPoints(inputText) {
  if (!inputText) return inputText;
  return inputText.replace(/•/g, "<br/>•");
}

export const getImageByFileType = (file) => {
  let src;
  switch (true) {
    case file.name.endsWith(".pdf"):
      src = "/pdf.jpg";
      break;
    case file.name.endsWith(".txt"):
      src = "/txt.jpg";
      break;
    case file.name.endsWith(".docx"):
      src = "/docx.jpg";
      break;
    case file.name.endsWith(".xlsx"):
      src = "/xlsx.jpg";
      break;
    default:
      src = "";
      break;
  }
  return src;
};
