import { Drawer, Collapse } from "antd";
import { UpOutlined } from "@ant-design/icons";
import { useDrawerContext } from "../../hooks/useDrawerContext";

import styles from "./NewUpdatesDrawer.module.css";
import { useGet } from "../../hooks";
import { routes } from "../../config/apiRoutes";
import { useEffect, useState } from "react";
import { updatesSorter } from "../../utils";

const Panel = Collapse.Panel;

export const NewUpdatesDrawer = () => {
  const { showUpdatesDrawer, setShowUpdatesDrawer, isMobileView } =
    useDrawerContext();
  const [updates, setUpdates] = useState([]);
  const [activeKey, setActiveKey] = useState(["1"]);

  const closeDrawer = () => {
    setShowUpdatesDrawer(false);
  };
  const { mutate: fetchUpdates } = useGet({
    endpoint: routes.adminGetUpdates,
    autoExecute: false,
    onSuccess: (resUpdates) => {
      setUpdates(updatesSorter(resUpdates));
    },
  });
  useEffect(() => {
    if (showUpdatesDrawer) {
      fetchUpdates();
    } else {
      setActiveKey(["1"]);
    }
  }, [showUpdatesDrawer]);

  return (
    <>
      <Drawer
        title="New updates"
        placement="right"
        onClose={closeDrawer}
        width={isMobileView ? "100%" : 300}
        open={showUpdatesDrawer}
        className={styles.updateStyles}
      >
        <Collapse
          size="small"
          accordion
          defaultActiveKey={["1"]}
          expandIconPosition="right"
          activeKey={activeKey}
          onChange={setActiveKey}
          expandIcon={({ isActive }) => (
            <UpOutlined rotate={isActive ? 0 : 180} />
          )}
        >
          {updates.map(({ title, data }, index) => (
            <Panel
              className={styles.updatesPanel}
              header={
                <span>
                  {index !== 0 ? title : <strong>{title} (Latest)</strong>}
                </span>
              }
              key={index + 1}
            >
              <ol type="1" className={styles.listStyles}>
                {data?.map((details) => (
                  <li
                    key={details.description}
                    dangerouslySetInnerHTML={{
                      __html: details.description,
                    }}
                  />
                ))}
              </ol>
            </Panel>
          ))}
        </Collapse>
      </Drawer>
    </>
  );
};
