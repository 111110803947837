import { useState } from "react";
import { Button, Modal } from "antd";
import CountdownTimer from "../../../components/popups/logoutCounter";
import { useMsal } from "@azure/msal-react";
import { useMutate } from "../../../hooks/useMutate";
import { routes } from "../../../config/apiRoutes";
import { useIdleTimer } from "react-idle-timer";

const AppLogout = () => {
  const [showAutoLogOff, SetShowAutoLogOff] = useState(false);
  const { instance } = useMsal();
  let counter = 30;
  const logoutCounter = 3000000;

  const { mutate: clearUserData } = useMutate({
    endpoint: routes.clearUserData,
  });

  const idleTimer = useIdleTimer({
    timeout: logoutCounter,
    onIdle: logoutAction,
    debounce: 500,
  });

  function logoutAction() {
    SetShowAutoLogOff(true);
  }

  function handleOkToLogOff() {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    clearUserData({ body: JSON.stringify({}) });
    localStorage.clear();
    instance.logoutRedirect();
  }

  function handleCancel() {
    SetShowAutoLogOff(false);
    idleTimer.reset();
  }

  return (
    <Modal
      title="Session Timeout"
      open={showAutoLogOff}
      destroyOnClose={true}
      maskClosable={false}
      closable={false}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            handleOkToLogOff();
          }}
        >
          Sign out
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            handleCancel();
          }}
        >
          Stay
        </Button>,
      ]}
    >
      <CountdownTimer
        initialSeconds={counter}
        onTimerUp={handleOkToLogOff}
      ></CountdownTimer>
    </Modal>
  );
};

export default AppLogout;
