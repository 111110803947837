import { PersonasListing } from "../modules/AdminModules/PersonasListing/PersonasListing";
import { UpdatesListing } from "../modules/AdminModules/UpdatesListing/UpdatesListing";

export const allowedFileTypes = [
  ".pdf, .text, .txt, .docx, .xlsx, .png, .jpg, .jpeg",
];

export const imageFileRegex = /\.(jpg|jpeg|png)$/i;
export const MAX_CHARACTERS = 255;

export const paginationOptions = {
  defaultPageSize: 5,
  showSizeChanger: true,
  pageSizeOptions: ["5", "10", "15"],
};
export const EXTERNAL_URLS = {
  learnIT:
    "https://bdouk.sharepoint.com/sites/LearningPathways/SitePages/Main%20AI%20Homepage.aspx?stay=true",
  insite:
    "https://insite.bdo.co.uk/sites/IT/aboutit/innovationdigital/ai/Pages/Our-guidance-on-the-use-of-AI.aspx",
  supportLink:
    "https://insite.bdo.co.uk/sites/knowledge/redirects/Pages/go.aspx?title=itsupporthub",
};
